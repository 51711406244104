var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orgLabelForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _vm.onlyOne || _vm.isEdit
            ? _c("div", { staticClass: "group-cell" }, [
                _c("div", { staticClass: "c-left" }, [
                  _c("span", [_vm._v("*")]),
                  _vm._v("标签层级："),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.form.tagLevel === 1 ? "一" : "二") + "级"),
                ]),
              ])
            : _vm._e(),
          _vm.isEdit && _vm.parentName
            ? _c("div", { staticClass: "group-cell" }, [
                _c("div", { staticClass: "c-left" }, [
                  _c("span", [_vm._v("*")]),
                  _vm._v("所属上级标签："),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.parentName) + "级")]),
              ])
            : _vm._e(),
          !_vm.onlyOne && !_vm.isEdit
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "标签层级",
                    rules: [
                      {
                        required: true,
                        message: "请选择标签层级",
                        trigger: "change",
                      },
                    ],
                    prop: "tagLevel",
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.tagLevelOps },
                    on: { change: _vm.tagLevelChange },
                    model: {
                      value: _vm.form.tagLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tagLevel", $$v)
                      },
                      expression: "form.tagLevel",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.tagLevel == 2 && !_vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "所属上级标签" } },
                [
                  _c("v-select", {
                    attrs: { options: _vm.parentTagLevel },
                    model: {
                      value: _vm.form.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentId", $$v)
                      },
                      expression: "form.parentId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标签名称",
                rules: [
                  {
                    required: true,
                    message: "请选择标签名称",
                    trigger: "blur",
                  },
                ],
                prop: "tagName",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "标签名称最多10个字符",
                  width: 300,
                  maxlength: 10,
                },
                model: {
                  value: _vm.form.tagName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tagName", $$v)
                  },
                  expression: "form.tagName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("v-textarea", {
                attrs: { placeholder: "请输入备注信息", maxlength: 200 },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c("v-select", {
                    attrs: { options: _vm.statusOps },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }