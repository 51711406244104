// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}orgTag/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}orgTag/exportOrgTagList`
// 获取一级标签Url
const getSuperiorTagUrl = `${API_CONFIG.butlerBaseURL}orgTag/getSuperiorTagByTagName`
// 新增/更新标签 提交Url
const updateOrgTagInfoUrl = `${API_CONFIG.butlerBaseURL}orgTag/sysOrgTag/updateOrgTagInfo`
// 获取标签详情
const getSysOrgTagByIdUrl = `${API_CONFIG.butlerBaseURL}orgTag/getSysOrgTagById/`
// 获取全部一级标签
const getAllSuperiorTagUrl = `${API_CONFIG.butlerBaseURL}orgTag/getAllSuperiorTag`
// 获取标签属性
const getTagAttributeListUrl = `${API_CONFIG.butlerBaseURL}orgTag/getTagAttributeListByOrgTagId/`
// 更新标签属性
const updategTagAttributeListUrl = `${API_CONFIG.butlerBaseURL}orgTag/updategTagAttributeList`
// 获取标签Url
const getDropDownSearchTagUrl = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 获取可配置的下拉选择项
const getTagAttrsURL = `${API_CONFIG.butlerBaseURL}orgTag/getTagAttributeSourceBySelect`

export {
  getListURL,
  exportListURL,
  getSuperiorTagUrl,
  updateOrgTagInfoUrl,
  getSysOrgTagByIdUrl,
  getAllSuperiorTagUrl,
  getTagAttributeListUrl,
  updategTagAttributeListUrl,
  getDropDownSearchTagUrl,
  getTagAttrsURL
}
