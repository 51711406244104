<template>
  <div class="orgLabelForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
      <div v-if="onlyOne || isEdit" class="group-cell">
        <div class="c-left"><span>*</span>标签层级：</div>
        <div>{{form.tagLevel===1 ? '一': '二'}}级</div>
      </div>
      <div v-if="isEdit && parentName" class="group-cell">
        <div class="c-left"><span>*</span>所属上级标签：</div>
        <div>{{parentName}}级</div>
      </div>
      <el-form-item v-if="!onlyOne && !isEdit" label="标签层级" :rules="[{ required: true, message: '请选择标签层级', trigger: 'change' }]" prop="tagLevel">
        <v-select v-model="form.tagLevel" :options="tagLevelOps" @change="tagLevelChange"></v-select>
      </el-form-item>
      <el-form-item v-if="form.tagLevel == 2 && !isEdit" label="所属上级标签">
        <v-select v-model="form.parentId" :options="parentTagLevel"></v-select>
      </el-form-item>
      <el-form-item label="标签名称" :rules="[{ required: true, message: '请选择标签名称', trigger: 'blur' }]" prop="tagName">
        <v-input v-model="form.tagName" placeholder="标签名称最多10个字符" :width="300" :maxlength="10"></v-input>
      </el-form-item>
      <el-form-item label="备注">
        <v-textarea v-model="form.remark" placeholder="请输入备注信息" :maxlength="200"></v-textarea>
      </el-form-item>
      <el-form-item v-if="isEdit" label="状态">
        <v-select v-model="form.status" :options="statusOps"></v-select>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import {
  updateOrgTagInfoUrl,
  getSysOrgTagByIdUrl,
  getAllSuperiorTagUrl
} from './api'
export default {
  data () {
    return {
      onlyOne: false,
      form: {
        tagLevel: 1,
        parentId: 0,
        status: 1,
        tagName: '',
        remark: ''
      },
      tagLevelOps: [
        {
          text: '一级',
          value: 1
        }, {
          text: '二级',
          value: 2
        }
      ],
      statusOps: [
        {
          text: '正常',
          value: 1
        },
        {
          text: '关闭',
          value: 2
        }
      ],
      submitUrl: updateOrgTagInfoUrl,
      submitConfig: {},
      parentTagLevel: [],
      isEdit: false,
      parentName: ''
    }
  },
  mounted () {
    let formId = this.$route.query.id
    if (formId) {
      this.isEdit = true
      this.$setBreadcrumb('编辑标签')
      this.getFormDetail(formId)
    } else {
      this.$setBreadcrumb('新增标签')
    }
    let addParentLabel = this.$store.getters.getPermission('addParentLabel')
    let addChildLabel = this.$store.getters.getPermission('addChildLabel')
    if (addParentLabel && !addChildLabel) {
      this.$set(this.form, 'tagLevel', 1)
      this.onlyOne = true
    }
    if (!addParentLabel && addChildLabel) {
      this.$set(this.form, 'tagLevel', 2)
      this.onlyOne = true
    }
    addChildLabel && this.getAllSuperiorTag()
  },
  methods: {
    submitBefore () {
      return true
    },
    getAllSuperiorTag () {
      this.$axios.get(getAllSuperiorTagUrl, {
        params: {
          isPartyTag: 0
        }
      }).then(res => {
        if (res.status == 100) { // eslint-disable-line
          let ops = res.data.map(value => {
            return {
              text: value.tagName,
              value: value.id
            }
          })
          this.parentTagLevel = ops
        }
      })
    },
    tagLevelChange (val) {
      if (val === 2 && this.form.parentId === 0) {
        this.form.parentId = this.parentTagLevel[0].value
      } else if (val === 1) {
        this.form.parentId = 0
      }
    },
    getFormDetail (id) {
      this.$axios.get(`${getSysOrgTagByIdUrl}${id}`).then(res => {
        if (res.status == 100) { // eslint-disable-line
          let { data } = res
          this.form = {
            tagLevel: data.tagLevel,
            parentId: data.parentId,
            status: data.status,
            tagName: data.tagName,
            remark: data.remark,
            id
          }
          if (data.parentId !== 0) this.parentName = data.parentName
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.orgLabelForm-container {
  text-align: left;
}
.group-cell{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    line-height: 33px;
  .c-left {
    display: inline-block;
    min-width: 112px;
    padding-right: 12px;
    text-align: right;
    color: #333333;
    // font-weight: 700;
    span {
      color: #F56C6C;
      margin-right: 4px;
    }
  }
}
</style>
